import { useEffect } from 'react';
import axios from 'axios';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { firebaseApp, firebaseInit } from '../../../../utils/auth/initFirebase';

const ConfirmedAccountToast = () => {
  useEffect(() => {
    const checkConfirmationToken = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('confirmation_token');

      if (token) {
        try {
          const { data } = await axios.put(
            `${process.env.NEXT_PUBLIC_CLOUDFUNCTIONS_BASE_URL}/loyalty_public/profile/confirm`,
            { token }
          );
          const { customToken } = data;
          await firebaseInit;
          const auth = getAuth(firebaseApp);
          await signInWithCustomToken(auth, customToken);
          window.location = '/foodservice';
        } catch (err) {
          // console.log('---', err);
        }
      }
    };

    checkConfirmationToken();
  }, []);

  return null;
};

export default ConfirmedAccountToast;
